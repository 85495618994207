<template>
  <div class="BossExpiredFreeTrial">
    
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol class="text-center">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="mr-4">⏰ Oups, votre période d'essai a expiré</h1>
              <div class="mt-4" style="font-size: 22px;">
                <p>
                  <strong>Votre période d'essai Eclerk a expiré.</strong>
                </p>
                <p>
                  Nous espérons que vous avez apprécié nos services.
                </p>
                <p>
                  Ne vous inquiétez pas, toutes vos données sont conservées et votre plateforme Eclerk 
                  pourra être réutilisée à l'identique dès la souscription d'un nouvel abonnement :)
                </p>
                <p> 
                  Il vous suffit de souscrire un abonnement pour retrouver toutes 
                  vos données et fonctionnalités.
                </p>
                <CRow class="mt-4">
                  <CCol class="text-center">
                    <CButton
                      color="primary"
                      shape="pill"
                      block class="px-4"
                      size="lg"
                      @click="$router.push('/abonnement/souscription')">
                      Souscrire mon abonnement
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>

    </CContainer>
  </div>
</template>

<script>

export default {
  name: 'BossExpiredFreeTrial',
}
</script>
